<template lang="pug">
div.boxSectionHeaderAndBody
  div#navtop(:class="{'anchoEscritorioSubnavCerrado': subNavClosed}")
    div.sectionmenu
      div.firstlevel
        router-link(:to="{name: 'DeliveryResumeHome', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Resume]
        router-link(:to="{name: 'DeliveryRankingRestaurantsHome', params: {paramCOD: this.paramCOD}, query: queryParams}") 
          span(v-if="isGenericEntity") #[translate Ranking de Negocios]
          span(v-else) #[translate Ranking de Restaurantes]
        router-link(:to="{name: 'DeliveryGPSHome', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate GPS]
        router-link(v-if="nodeLevel  && hasCompetence" :class="{'router-link-active': ['DeliveryCompetenceGlobal', 'DeliveryCompetenceMap'].includes($route.name) }" :to="{name: 'DeliveryCompetenceHome', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Competence]
        router-link(v-if="hasDeliveryAnalytics" :to="{name: 'DeliveryPosicionamientoHome', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Posicionamiento en webs de delivery]
        select-country-and-logout
      div.secondlevel(:class="{'d-none': this.$router.history.current.name.indexOf('DeliveryValoration') < 0 && this.$router.history.current.name.indexOf('DeliveryCompetence') < 0}")
        .submenuValoration
          router-link(:to="{name: 'DeliveryCompetenceGlobal', params: {paramCOD: this.$route.params.paramCOD}, query: queryParams}") #[translate Global]
          router-link(v-if="$config.featureConfig.mapCompetence || userName.indexOf('gastroranking.es') > -1 || userName.indexOf('theysay.me') > -1" :to="{name: 'DeliveryCompetenceMap', params: {paramCOD: this.$route.params.paramCOD}, query: queryParams}") #[translate Map]
          router-link(v-if="$config.featureConfig.mapCompetence || userName.indexOf('gastroranking.es') > -1 || userName.indexOf('theysay.me') > -1" :to="{name: 'DeliveryCompetenceCompareLocals', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Comparar locales]


  div.gridSection
    div.sectionmenu
      filters-and-bread-crumb(:paramCOD="paramCOD" :isDelivery="true")
      warning-not-delivery-not-internet(v-if="itemNotHasDelivery" type="delivery")
      router-view.bg-gray.pt-0

</template>

<script>
import FiltersAndBreadCrumb from "@/components/filters/filtersAndBreadCrumb.vue"
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue"

import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import brandMixin from "@/mixins/common.js"
import WarningNotDeliveryNotInternet from '@/components/common/WarningNotDeliveryNotInternet.vue'
export default {
  props: ['paramCOD', 'type', 'site', 'typeRestaurant', 'after', 'before'],
  mixins: [commonMixins, brandMixin],
  data() {
    return {
    }
  },
  components: { FiltersAndBreadCrumb, WarningNotDeliveryNotInternet, SelectCountryAndLogout },
  created() {
    this.$store.commit(types.UPDATE_SECTION, 8)
    this.loadUrlParams()

  },
  watch: {
    filterForSites: function () {
      this.$store.dispatch('getSitesOpinions')
    },
  },
}
</script>

